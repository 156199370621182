import React, { useState } from "react"
import { Link } from "gatsby"
import "./navbar.scss"

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <nav className="navbar">
      <div className="navContent">
        <div className="brand">
          <Link to="/">
            <i className="fas fa-home"></i> James<strong>Ku</strong>
          </Link>
        </div>

        <ul className={"nav-links" + (menuOpen ? " open" : "")}>
          <li>
            <Link to="/" activeClassName="active" className="nav-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/timeline" activeClassName="active" className="nav-link">
              Timeline
            </Link>
          </li>
          <li>
            <Link to="/projects" activeClassName="active" className="nav-link">
              Projects
            </Link>
          </li>
          <li>
            <Link to="/posts" activeClassName="active" className="nav-link">
              Posts
            </Link>
          </li>
        </ul>

        <div className="socialLinks">
          <a
            href="https://github.com/imjamesku"
            style={{ marginLeft: 5, marginRight: 5 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github fa-2x"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/jiajiunku/"
            style={{ marginLeft: 5, marginRight: 5 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-2x"></i>
          </a>
        </div>
        <div
          role="button"
          tabIndex={0}
          className="hamburger"
          onClick={() => setMenuOpen(!menuOpen)}
          onKeyDown={() => setMenuOpen(!menuOpen)}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
